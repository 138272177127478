import React, { FC } from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import AboutStaff from "../components/about/AboutStaff";
import AboutCPL from "../components/about/AboutCPL";

import Footer from "../components/sections/footer";
import theme from "@studytools/core/ui/theme/muiTheme";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ThemeProvider } from "@material-ui/core";

const TheTeamPage: FC = () => {
  return (
    <Layout>
      <SEO title="The Team" description="About Ako Map Cognitive Performance Labs" />
      <Navigation />
      <ThemeProvider theme={theme}>
        <AboutCPL />
        <AboutStaff />
      </ThemeProvider>
      <Footer />
    </Layout>
  );
};

export default TheTeamPage;
